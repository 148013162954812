@mixin title {
    font-family: 'Inter', sans-serif;
    font-weight: 900;
    font-size: 1.5rem;
    line-height: 3.5rem;
    color: var(--color-text-primary);
}

@mixin subtitle {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 1rem;
    line-height: 2.5rem;
    color: var(--color-text-primary);
}

@mixin body {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: .8rem;
    line-height: 1.5rem;
    color: var(--color-text-primary);
}

@mixin subbody {
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    font-size: .6rem;
    line-height: 1.5rem;
    color: var(--color-text-primary);
}
